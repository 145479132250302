<template>
    <div class="trial">
        <BaseInfo ref="baseInfo"></BaseInfo>
        <el-form
            ref="form"
            label-position="left"
            size="small"
            label-width="80px"
        >
            <el-form-item
                :label="'域名' + (index + 1)"
                style="margin-left: -10px"
                :key="item.id"
                v-for="(item, index) in tableRow.nicheDomainBOList"
            >
                <span class="brand">{{ item.domain || '- -' }}</span>
                <span class="success" v-if="item.auditStatus == 1"> 成功 </span>
                <span class="faid" v-if="item.auditStatus == 2"> 失败 </span>
                <i
                    v-if="item.auditStatus == 2"
                    @click="delDomain2(item, index)"
                    class="el-icon-delete"
                ></i>
            </el-form-item>
            <el-form-item
                label="添加域名"
                required
                :key="index"
                style="margin-left: -10px"
                v-for="(item, index) in nicheDomainDOList"
                class="domain_box"
            >
                <el-input
                    style="width: 240px"
                    v-model="item.domain"
                    @input="domainInput(item.domain, index)"
                    placeholder="请输入域名"
                ></el-input>
                <p style="color: #666666" v-if="!item.tag && item.domain">
                    <i
                        class="el-icon-warning-outline"
                        style="color: #f5a623"
                    ></i>
                    本域名重复无法报备，请修改或删除
                </p>
                <div class="domain_rules_box" v-if="item.domain">
                    <el-button
                        v-if="item.tag"
                        type="primary"
                        class="el-icon-success"
                        circle
                        size="mini"
                    ></el-button>
                    <el-button
                        v-else
                        class="el-icon-warning"
                        type="primary"
                        circle
                        size="mini"
                    ></el-button>
                </div>
                <div class="domain_update_box">
                    <el-button
                        v-if="index == 0"
                        @click="addDomain"
                        type="primary"
                        class="el-icon-plus"
                        style="background: #fff"
                        circle
                        size="mini"
                    ></el-button>
                    <el-button
                        v-if="index > 0"
                        @click="delDomain(index)"
                        class="el-icon-minus"
                        type="primary"
                        circle
                        size="mini"
                        style="background: #fff"
                    ></el-button>
                </div>
            </el-form-item>
            <el-form-item label="备注" style="margin-left: -10px">
                <el-input
                    type="textarea"
                    v-model="form.applyRemark"
                    size="small"
                    placeholder="请输入备注"
                    style="width: 240px"
                    rows="6"
                ></el-input>
            </el-form-item>
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: 270px;
                "
                @click="onClose"
                >取 消</el-button
            >

            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff"
                size="mini"
                @click="onSubmit"
                >确 认</el-button
            >
        </div>
    </div>
</template>
<script>
// import { domainAdd, domainList } from '@/api/user/ae/ali';
// import { getCustomerId } from '@/api/user/ae/customer';
import {
    reportUpdate,
    reportList,
    delDomain as deleteDomain,
} from '@/api/report/report.js';
import BaseInfo from '../components/baseInfo.vue';
export default {
    data() {
        return {
            tableRow: {},
            form: {},
            nicheDomainDOList: [{}],
        };
    },
    components: {
        BaseInfo,
    },
    methods: {
        getData(row) {
            console.log(row);
            this.nicheDomainDOList = [{}];
            sessionStorage.setItem('customerlist', 1);
            this.tableRow = row;
            setTimeout(() => {
                this.$refs.baseInfo.getData(row);
            }, 0);
        },
        addDomain() {
            this.nicheDomainDOList.push({});
        },
        delDomain(index) {
            this.nicheDomainDOList.splice(index, 1);
        },
        delDomain2(item, index) {
            let data = {
                param: {
                    id: item.id,
                },
            };
            deleteDomain(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.tableRow.nicheDomainBOList.splice(index, 1);
                }
            });
        },
        // 域名重复校验
        domainInput(val, index) {
            if (/^www/.test(val)) {
                this.nicheDomainDOList[index].domain = '';
                return this.$message.error('域名不能包含www');
            }
            if (
                this.nicheDomainDOList.some((item, idx) => {
                    if (idx != index) {
                        if (item.domain && item.domain == val) {
                            return true;
                        }
                    }
                }) ||
                this.tableRow.nicheDomainBOList.some((item, idx) => {
                    if (idx != index) {
                        if (item.domain && item.domain == val) {
                            return true;
                        }
                    }
                })
            ) {
                this.nicheDomainDOList[index].tag = false;
                return;
            }
            let data = {
                param: {
                    aliDomain: val,
                    reportStatusList: [3, 8, 9, 18],
                    productType: 2,
                },
                pageSize: 0,
                pageNum: 0,
            };
            reportList(data).then((res) => {
                if (res.code == 200) {
                    if (res.data.total > 0) {
                        this.nicheDomainDOList[index].tag = false;
                    } else {
                        this.tableRow.nicheDomainBOList &&
                        this.tableRow.nicheDomainBOList.length > 0
                            ? this.tableRow.nicheDomainBOList.forEach(
                                  (item) => {
                                      this.nicheDomainDOList.forEach((itm) => {
                                          if (item.domain == itm.domain) {
                                              this.nicheDomainDOList[
                                                  index
                                              ].tag = false;
                                          } else {
                                              this.nicheDomainDOList[
                                                  index
                                              ].tag = true;
                                          }
                                      });
                                  }
                              )
                            : (this.nicheDomainDOList[index].tag = true);
                    }
                    this.nicheDomainDOList = JSON.parse(
                        JSON.stringify(this.nicheDomainDOList)
                    );
                }
            });
        },
        onSubmit() {
            let data = {
                param: {
                    reportStatus: 15,
                    id: this.tableRow.id,
                },
            };
            for (let i in this.nicheDomainDOList) {
                if (!this.nicheDomainDOList[i].domain) {
                    return this.$message.error('请输入域名');
                }
                if (!this.nicheDomainDOList[i].tag) {
                    return this.$message.error('域名不能重复');
                }
            }
            if (
                this.tableRow.nicheDomainBOList.filter((item) => {
                    return item.auditStatus == 1;
                }).length +
                    this.nicheDomainDOList.length >
                10
            ) {
                return this.$message.error(
                    '每条商机可报备成功域名不能超过10个'
                );
            }
            data.param.id = this.tableRow.id;
            data.param.nicheDomainDOList = this.nicheDomainDOList.map(
                (item) => {
                    return {
                        domain: item.domain,
                    };
                }
            );
            data.param.applyRemark = this.form.applyRemark;
            reportUpdate(data).then((res) => {
                if (res.code == 200) {
                    let applyNum = Number(sessionStorage.getItem('applyNum'));
                    sessionStorage.setItem('applyNum', applyNum + 1);
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            this.nicheDomainDOList = [{}];
            this.form = {};
            this.$emit('close');
            sessionStorage.removeItem('customerlist');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.trial {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .el-form {
        width: 80%;
        margin: 0 auto;
    }
    .domain_box {
        /deep/ .el-input__inner {
            padding-right: 20px !important;
        }
        .el-button--mini.is-circle {
            padding: 2px;
            background: #fff;
            color: #2370eb;
            font-weight: 600;
            width: 20px;
            height: 20px;
            border: 2px solid #2370eb;
        }
        .domain_rules_box {
            position: absolute;
            right: 92px;
            top: 2px;
            .el-button--mini.is-circle {
                padding: 2px;
                width: 20px;
                height: 20px;
                background-color: transparent;
                border: none;
                margin-left: 0;
                font-size: 16px;
                font-weight: 600;
            }
            .el-icon-success {
                color: #46a136;
            }
            .el-icon-warning {
                color: #f5a623;
            }
        }
        .domain_update_box {
            position: absolute;
            right: 60px;
            top: 0px;
        }
    }
    .brand {
        display: inline-block;
        width: 240px;
        box-sizing: border-box;
        padding-left: 10px;
        background: #fafafa;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
    .bottom-but {
        width: 500px;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        position: fixed;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
}
/deep/ .el-textarea__inner {
    resize: none;
}
.success {
    width: 45px;
    height: 20px;
    margin-left: -50px;
    border-radius: 3px;
    line-height: 20px;
    display: inline-block;
    text-align: center;
    border: 1px solid #2370eb;

    color: #2370eb;
}
.faid {
    width: 45px;
    height: 20px;
    margin-left: -50px;
    border-radius: 3px;
    line-height: 20px;
    display: inline-block;
    text-align: center;
    border: 1px solid #d0021b;
    color: #d0021b;
}
.el-icon-delete {
    color: #d0021b;
    font-size: 18px;
    margin-left: 10px;
    vertical-align: middle;
    cursor: pointer;
}
</style>
