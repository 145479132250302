<template>
    <div class="customer">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>报备库</span>
                    <el-select
                        v-model="searchVal.libraryCategoryVal"
                        size="small"
                        style="width: 100px"
                        clearable
                        placeholder="全部"
                        @change="getData()"
                    >
                        <el-option
                            v-for="item in FilingList"
                            :key="item.id"
                            :label="item.accountName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <span v-if="btnP.channel">报备渠道</span>
                    <el-select
                        v-if="btnP.channel"
                        v-model="searchVal.channelManageId"
                        size="small"
                        style="width: 220px"
                        placeholder="请选择报备渠道"
                        clearable
                        @change="getData()"
                    >
                        <el-option
                            v-for="item in channeManagelListData"
                            :key="item.id"
                            :label="item.channelName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                    <el-select
                        class="slee"
                        v-model="searchVal.field"
                        size="small"
                        style="width: 100px; margin: 0 10px"
                        placeholder="请选择"
                    >
                        <el-option label="试用到期" :value="1"></el-option>
                        <el-option label="报备时间" :value="2"></el-option>
                        <el-option label="报备到期" :value="3"></el-option>
                        <el-option label="创建时间" :value="4"></el-option>
                    </el-select>
                    <el-radio-group v-model="searchVal.followTime" size="small">
                        <el-radio-button
                            label="今日"
                            @click.native.prevent="clickFollow('今日')"
                        ></el-radio-button>
                        <el-radio-button
                            label="近7天"
                            @click.native.prevent="clickFollow('近7天')"
                        ></el-radio-button>
                        <el-radio-button
                            label="本月"
                            @click.native.prevent="clickFollow('本月')"
                        ></el-radio-button>
                    </el-radio-group>
                    <el-date-picker
                        size="small"
                        v-model="searchVal.followDate"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width: 240px; margin-left: 19px"
                        clearable
                        @change="expireTime"
                    >
                    </el-date-picker>
                </p>
                <p>
                    <el-button
                        type="primary"
                        style="
                            margin-right: 16px;
                            border-color: #2370eb;
                            background: #2370eb;
                        "
                        size="small"
                        @click="getTableData2()"
                        >查询</el-button
                    >
                    <el-button
                        @click="searchEmpty"
                        type="primary"
                        style="
                            margin: 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                            margin-right: 16px;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                </p>
            </div>
            <div class="search-two">
                <span>查询</span>
                <el-input
                    size="small"
                    style="width: 215px"
                    placeholder="请输入报备名称/客户名称/域名"
                    v-model.trim="name"
                    clearable
                >
                </el-input>
                <el-button
                    @click="getTableData()"
                    type="primary"
                    size="small"
                    icon="el-icon-search"
                    style="border-color: #2370eb; background: #2370eb"
                ></el-button>
            </div>
        </div>
        <div class="table-title">
            <div style="flex: 1">
                <div class="title_left_btn">
                    <img
                        src="@/img/test.gif"
                        v-if="applyNum > 0 && active != '申请中'"
                        class="toast_gif"
                        alt=""
                    />
                    <img
                        src="@/img/test.gif"
                        v-if="saveNum > 0 && active != '草稿箱'"
                        class="toast_gif2"
                        alt=""
                    />
                    <span
                        @click="getTableData2($event)"
                        :class="active == '已报备' ? 'active' : ''"
                        >已报备</span
                    >
                    <!-- <el-badge
                        v-if="applyNum > 0 && active != '申请/办理中'"
                        :value="applyNum"
                        class="item"
                        style="margin-left:12px;margin-right:12px;margin-top:-3px"
                    > -->
                    <span
                        @click="getTableData2($event)"
                        :class="active == '申请中' ? 'active' : ''"
                        >申请中</span
                    >

                    <!-- </el-badge> -->
                    <!-- <span
                        v-else
                        @click="getTableData2($event)"
                        :class="active == '申请/办理中' ? 'active' : ''"
                        >申请/办理中</span
                    > -->
                    <span
                        @click="getTableData2($event)"
                        :class="active == '试用中' ? 'active' : ''"
                        >试用中</span
                    >
                    <span
                        @click="getTableData2($event)"
                        :class="active == '试用到期' ? 'active' : ''"
                        >试用到期</span
                    >
                    <!-- <el-badge
                        :value="saveNum"
                        class="item"
                        style="margin-left:12px;margin-right:12px;margin-top:-3px"
                    > -->
                    <span
                        @click="getTableData2($event)"
                        :class="active == '草稿箱' ? 'active' : ''"
                        >草稿箱</span
                    >
                    <!-- </el-badge> -->
                    <span
                        @click="getTableData2($event)"
                        :class="active == '已成交' ? 'active' : ''"
                        >已成交</span
                    >
                    <span
                        @click="getTableData2($event)"
                        :class="active == '已失效' ? 'active' : ''"
                        >已失效</span
                    >
                </div>
            </div>
            <div class="info">
                <i class="el-icon-warning-outline" style="color: #f5a623"></i>
                <span>最大报备名额：</span
                ><em style="color: #d0021b">{{
                    accountTotal.maxReportNumber || 0
                }}</em>
                <span>已报备：</span
                ><em style="color: #2370eb">{{
                    accountTotal.consumedNumber +
                        accountTotal.channelReportNumber || 0
                }}</em>
            </div>
            <button
                class="add"
                style="margin-left: 10px"
                @click="onAddReport(1)"
                v-show="btnP.add"
            >
                <i class="iconfont icon-faqibaobei"></i> 报备
            </button>
            <button
                class="add"
                style="margin-left: 10px"
                @click="onAddReport(2)"
                v-show="btnP.channel"
            >
                <i class="iconfont icon-faqibaobei"></i> 渠道报备
            </button>
        </div>
        <Table
            ref="table"
            @onRevoke="onRevoke"
            @totalNum="totalNum"
            @onTrial="onTrial"
            @onDelay="onDelay"
            @onGiveUp="onGiveUp"
            @onDomain="onDomain"
            @onChangeTrial="onChangeTrial"
            @onEdit="onEdit"
            @onReportDetail="onReportDetail"
            @onHistory="onHistory"
            @onReport="onReport"
            @onDetail="onDetails"
            @onAgainReport="onAgainReport"
        ></Table>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 报备 -->
        <el-drawer
            :visible.sync="drawerReport"
            :direction="direction"
            size="500px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    阿里报备
                </div>
            </template>
            <AddReport @close="handleClose" ref="addReport" />
        </el-drawer>
        <!-- 编辑 -->
        <el-drawer
            :visible.sync="drawerUpdateReport"
            :direction="direction"
            size="500px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    阿里报备
                </div>
            </template>
            <UpdateReport @close="handleClose" ref="updateReport" />
        </el-drawer>
        <!-- 重新报备 -->
        <el-drawer
            :visible.sync="drawerAgainReport"
            :direction="direction"
            size="500px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    重新报备
                </div>
            </template>
            <UpdateReport @close="handleClose" ref="updateReport" />
        </el-drawer>
        <!-- 撤销报备 -->
        <el-dialog
            :visible.sync="dialogrevoke"
            width="560px"
            :before-close="handleDialog"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    撤销报备
                </div>
            </template>
            <p class="dl_con">
                <i class="el-icon-warning-outline"></i>
                即将撤销当前报备申请，是否确认？
            </p>
            <div slot="footer" class="dialog_footer">
                <el-button @click="dialogrevoke = false" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    @click="revokeReport"
                    round
                    size="mini"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <!-- 报备延期 -->
        <el-dialog
            :visible.sync="dialogDelay"
            width="560px"
            :before-close="handleDialog"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    报备延期
                </div>
            </template>
            <Delay ref="delay"></Delay>
            <div slot="footer" class="dialog_footer">
                <el-button
                    @click="
                        () => {
                            this.dialogDelay = false;
                            this.$refs.delay.$data.delayForm = {};
                        }
                    "
                    round
                    size="mini"
                    >取 消</el-button
                >
                <el-button type="primary" @click="delayReport" round size="mini"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <!-- 试用 -->
        <el-drawer
            :visible.sync="drawerTrial"
            :direction="direction"
            :before-close="handleClose"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    试用申请
                </div>
            </template>
            <Trial @close="handleClose" ref="trial" />
        </el-drawer>
        <!-- 撤销报备 -->
        <el-dialog
            :visible.sync="dialogGiveUp"
            width="560px"
            :before-close="handleDialog"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    放弃报备
                </div>
            </template>
            <p class="dl_con">
                <i class="el-icon-warning-outline"></i>
                即将提交申请以放弃报备，是否确认？
            </p>
            <div slot="footer" class="dialog_footer">
                <el-button @click="dialogGiveUp = false" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    @click="giveUpReport"
                    round
                    size="mini"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <!-- 添加域名 -->
        <el-drawer
            :visible.sync="drawerAddDomain"
            :direction="direction"
            :before-close="handleClose"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    添加域名
                </div>
            </template>
            <AddDomain @close="handleClose" ref="domain" />
        </el-drawer>
        <!-- 变更试用 -->
        <el-dialog
            :visible.sync="dialogChangeTrial"
            width="560px"
            :before-close="handleDialog"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    变更试用
                </div>
            </template>
            <ChangeTrial @close="handleClose" ref="change" />
            <div slot="footer" class="dialog_footer">
                <el-button
                    @click="
                        () => {
                            this.dialogChangeTrial = false;
                            this.$refs.change.$data.form = {
                                companyDomain: '',
                            };
                        }
                    "
                    round
                    size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    @click="submitChangeTrial"
                    round
                    size="mini"
                    >保 存</el-button
                >
            </div>
        </el-dialog>
        <!-- 业务操作历史 -->
        <el-drawer
            :visible.sync="drawerHistory"
            :direction="direction"
            :before-close="handleClose"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    报备信息
                </div>
            </template>
            <History @close="handleClose" ref="history" />
        </el-drawer>
        <!-- 申请报备 -->
        <el-dialog
            :visible.sync="dialogApply"
            width="560px"
            :before-close="handleDialog"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    申请报备
                </div>
            </template>
            <p class="dl_con">
                <i class="el-icon-warning-outline"></i>
                即将提交报备申请，需要业务操作协作处理；<br />
                提交后无法编辑报备信息，是否确认？
            </p>
            <div slot="footer" class="dialog_footer">
                <el-button @click="dialogApply = false" round size="mini"
                    >取 消</el-button
                >
                <el-button type="primary" @click="applyReport" round size="mini"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Table from './table/table.vue';
import AddReport from './add/addReport.vue';
import Trial from './trial/trial.vue';
import AddDomain from './addDomain/addDomain.vue';
import ChangeTrial from './trial/changeTrial.vue';
import Detail from './details/details.vue';
import Delay from './delay/delay.vue';
import History from './history/history.vue';
import UpdateReport from './update/updateReport.vue';
import { departmentList } from '@/api/admin/framework/framework.js';
import { getCustomerId } from '@/api/user/ae/customer';
import { channeManagelList } from '@/api/reportchannel/reportchannel';
import { nameGet } from '@/api/customer/customer.js';
import {
    reportUpdate,
    reportGet,
    reportHistory,
    accountGet,
    accountList,
} from '@/api/report/report.js';
export default {
    components: {
        Trial,
        AddDomain,
        ChangeTrial,
        Detail,
        Table,
        AddReport,
        Delay,
        History,
        UpdateReport,
    },
    data() {
        return {
            active: '已报备',
            fold: true,
            searchVal: {
                field: 1,
                followTime: '',
                followDate: [],
                libraryCategoryVal: '',
            },
            field: 1,
            channeManagelListData: [],
            aliReportStatusVal: '已报备',
            aliTrialStatusVal: '',
            followData: [],
            followAdminName: '',
            assistData: [],
            synergyAdminName: '',
            tableData: [],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            drawerReport: false,
            drawerTrial: false,
            drawerUpdateReport: false,
            drawerAgainReport: false,
            dialogChoice: false,
            dialogrevoke: false,
            dialogDelay: false,
            dialogApply: false,
            dialogGiveUp: false,
            dialogAddlabel: false,
            drawerAddDomain: false,
            drawerReportDetail: false,
            drawerHistory: false,
            dialogChangeTrial: false,
            drawer: false,
            direction: 'rtl',
            isGet: false,
            dialog: false,
            dialogTitle: '',
            dialogContent: '',
            dialogType: '',
            row: '',
            trialTotal: '',
            statistics: {},
            isColor: 0,
            btnP: {},
            dempData: [],
            dempVal: '',
            isPage: false,
            pageData: {},

            delayForm: {},
            tableRow: {},
            getOneAliReport: {},
            name: '',
            applyNum: 0,
            saveNum: 0,
            accountTotal: {},
            FilingList: [],
        };
    },
    mounted() {
        if (sessionStorage.getItem('pageData')) {
            this.getPageData();
        } else {
            this.isPage = true;
        }
        if (sessionStorage.getItem('showNum')) {
            sessionStorage.setItem(
                'saveNum',
                JSON.parse(sessionStorage.getItem('showNum')).saveNum
            );
            sessionStorage.setItem(
                'applyNum',
                JSON.parse(sessionStorage.getItem('showNum')).applyNum
            );
            sessionStorage.removeItem('showNum');
        }
        if (sessionStorage.getItem('companyName')) {
            this.name = sessionStorage.getItem('companyName');
            sessionStorage.removeItem('companyName');
        }
        this.getBtn();
    },
    methods: {
        getBtn() {
            // if (
            //     sessionStorage.getItem('saveNum') ||
            //     sessionStorage.getItem('applyNum')
            // ) {
            sessionStorage.setItem(
                'saveNum',
                sessionStorage.getItem('saveNum') || 0
            );
            sessionStorage.setItem(
                'applyNum',
                sessionStorage.getItem('applyNum') || 0
            );
            // }

            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;

            this.getData();

            let obj = {
                param: {
                    productType: 2,
                },
                pageNum: 0,
                pageSize: 0,
            };
            accountList(obj).then((res) => {
                this.FilingList = res.data.list;
            });
            if (btn.channel) {
                this.channeManagelList();
            }
        },
        getData() {
            this.saveNum = sessionStorage.getItem('saveNum');
            this.applyNum = sessionStorage.getItem('applyNum');
            this.$refs.table.getData(
                this.active,
                this.name,
                this.searchVal,
                this.currentPage,
                this.pagesize,
                this.btnP
            );
            this.accountGet();
        },
        channeManagelList() {
            let data = {
                param: {},
                pageNum: 0,
                pageSize: 0,
            };

            channeManagelList(data).then((res) => {
                this.channeManagelListData = res.data.list;
            });
        },
        getPageData() {
            this.currentPage = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageNum;
            this.pagesize = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageSize;
            this.name = JSON.parse(sessionStorage.getItem('pageData')).name;
            this.searchVal = JSON.parse(
                sessionStorage.getItem('pageData')
            ).searchVal;
            this.active = JSON.parse(sessionStorage.getItem('pageData')).active;

            sessionStorage.removeItem('pageData');
            this.isPage = true;
        },
        getTableData() {
            this.currentPage = 1;
            this.active = '';
            this.pagesize = 20;
            this.searchVal = {
                field: 1,
                followTime: '',
                followDate: [],
                libraryCategoryVal: '',
            };
            this.getData();
        },
        getTableData2(e) {
            this.currentPage = 1;
            if (e) {
                this.active = e.target.innerText;
                if (e.target.innerText == '申请中') {
                    sessionStorage.removeItem('applyNum');
                }
                if (e.target.innerText == '草稿箱') {
                    sessionStorage.removeItem('saveNum');
                }
            }

            this.getData();
        },
        totalNum(row) {
            this.total = row;
        },
        getDempData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.dempData = res.data;
            });
        },
        clickFollow(e) {
            e === this.searchVal.followTime
                ? (this.searchVal.followTime = '')
                : (this.searchVal.followTime = e);
            this.searchVal.followDate = [];
            this.getData();
        },

        // 时间输入框变化
        expireTime() {
            if (
                this.searchVal.followDate &&
                this.searchVal.followDate.length > 0
            ) {
                this.searchVal.followTime = '';
            }
            this.getData();
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        // 获取单条数据
        reportGet() {
            let data = {
                param: {
                    id: this.tableRow.id,
                },
            };
            reportGet(data).then((res) => {
                if (res.code == 200) {
                    this.getOneAliReport = res.data;
                    if (this.drawerUpdateReport) {
                        this.accountGet(res.data.nicheCategory);
                    }
                    if (this.drawerAddDomain) {
                        this.$refs.domain.getData(res.data);
                    }
                    if (this.dialogDelay) {
                        this.$refs.delay.getData(res.data);
                    }
                    if (this.drawerTrial) {
                        this.$refs.trial.getData(res.data);
                    }
                    if (this.dialogChangeTrial) {
                        this.$refs.change.getData(res.data);
                    }
                    if (this.drawerAgainReport) {
                        this.accountGet(res.data.nicheCategory);
                    }
                    if (this.drawerHistory) {
                        this.reportHistory(res.data);
                    }
                }
            });
        },
        // 报备
        onAddReport(nicheCategory) {
            this.drawerReport = true;
            this.accountGet(nicheCategory);
        },
        accountGet(nicheCategory = '') {
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                    productType: 2,
                },
            };
            accountGet(data).then((res) => {
                if (res.code == 200) {
                    this.accountTotal = res.data;
                    if (this.drawerReport) {
                        if (nicheCategory == 2) {
                            this.$refs.addReport.getData('', res.data, true);
                            this.$refs.addReport.channelManagelList();
                        } else {
                            this.$refs.addReport.getData('', res.data, false);
                        }
                    }
                    if (this.drawerUpdateReport) {
                        if (nicheCategory == 2) {
                            this.$refs.updateReport.getEditData(
                                this.getOneAliReport,
                                res.data,
                                0,
                                true
                            );
                            this.$refs.updateReport.channelManagelList();
                        } else {
                            this.$refs.updateReport.getEditData(
                                this.getOneAliReport,
                                res.data,
                                0,
                                false
                            );
                        }
                    }
                    if (this.drawerAgainReport) {
                        if (nicheCategory == 2) {
                            this.$refs.updateReport.getEditData(
                                this.getOneAliReport,
                                res.data,
                                1,
                                true
                            );
                            this.$refs.updateReport.channelManagelList();
                        } else {
                            this.$refs.updateReport.getEditData(
                                this.getOneAliReport,
                                res.data,
                                1,
                                false
                            );
                        }
                    }
                }
            });
        },
        // 撤销报备弹窗
        onRevoke(row) {
            this.dialogrevoke = true;
            this.tableRow = row;
        },
        // 撤销报备
        revokeReport() {
            let data = {
                param: {
                    id: this.tableRow.id,
                    reportStatus: 7,
                },
            };
            reportUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleDialog();
                }
            });
        },
        // 试用
        onTrial(row) {
            this.tableRow = row;
            this.drawerTrial = true;
            this.reportGet();
        },
        // 报备延期弹窗
        onDelay(row) {
            this.tableRow = row;
            this.dialogDelay = true;
            this.reportGet();
        },
        // 报备延期
        delayReport() {
            let data = {
                param: {
                    id: this.tableRow.id,
                    reportStatus: 11,
                },
            };
            if (!this.$refs.delay.$data.delayForm.delayDays) {
                return this.$message.error('请输入延期天数');
            }

            if (this.$refs.delay.$data.delayForm.applyRemark) {
                data.param.applyRemark =
                    this.$refs.delay.$data.delayForm.applyRemark;
            }
            data.param.delayDays = this.$refs.delay.$data.delayForm.delayDays;
            reportUpdate(data).then((res) => {
                if (res.code == 200) {
                    let applyNum = Number(sessionStorage.getItem('applyNum'));
                    sessionStorage.setItem('applyNum', applyNum + 1);
                    this.$refs.delay.$data.form = {};
                    this.$message.success('操作成功');
                    this.handleDialog();
                }
            });
        },
        // 放弃报备弹窗
        onGiveUp(row) {
            this.dialogGiveUp = true;
            this.tableRow = row;
        },
        // 放弃报备
        giveUpReport() {
            let data = {
                param: {
                    id: this.tableRow.id,
                    reportStatus: 13,
                },
            };
            reportUpdate(data).then((res) => {
                if (res.code == 200) {
                    let applyNum = Number(sessionStorage.getItem('applyNum'));
                    sessionStorage.setItem('applyNum', applyNum + 1);
                    this.$message.success('操作成功');
                    this.handleDialog();
                }
            });
        },
        onAgainReport(row) {
            this.drawerAgainReport = true;
            this.tableRow = row;
            this.reportGet();
        },
        // 添加域名弹窗
        onDomain(row) {
            this.drawerAddDomain = true;
            this.tableRow = row;
            this.reportGet();
        },
        // 变更试用弹窗
        onChangeTrial(row) {
            this.dialogChangeTrial = true;
            this.tableRow = row;
            this.reportGet();
        },
        // 变更试用
        submitChangeTrial() {
            let data = {
                param: {
                    id: this.tableRow.id,
                    trialStatus: 15,
                },
            };
            if (!this.$refs.change.$data.form.companyDomain) {
                return this.$message.error('请选择域名');
            }

            if (this.$refs.change.$data.form.applyRemark) {
                data.param.applyRemark =
                    this.$refs.change.$data.form.applyRemark;
            }
            data.param.trialDomain = this.$refs.change.$data.form.companyDomain;
            reportUpdate(data).then((res) => {
                if (res.code == 200) {
                    let applyNum = Number(sessionStorage.getItem('applyNum'));
                    sessionStorage.setItem('applyNum', applyNum + 1);
                    this.$refs.change.$data.form = {
                        companyDomain: '',
                    };
                    this.$message.success('操作成功');
                    this.handleDialog();
                }
            });
        },
        // 编辑
        onEdit(row) {
            this.drawerUpdateReport = true;
            this.tableRow = row;
            this.reportGet();
        },
        // 申请报备弹窗
        async onReport(row) {
            this.tableRow = row;
            if (row.channelManageName) {
                this.dialogApply = true;
            } else {
                let res = await this.nameGet(row.companyName);
                let res2 = await this.getCustomerId();
                if (
                    res2.data.enterpriseType == 1 &&
                    res.data.aiChaStatus != 1 &&
                    row.areaType == 1
                ) {
                    return this.$message.error(
                        '客户名称有误，无法对客户进行报备'
                    );
                }
                if (
                    Number(this.accountTotal.maxReportNumber) -
                        Number(this.accountTotal.consumedNumber) <
                    1
                ) {
                    return this.$message.error(
                        '报备的名额大于分配的最大名额了'
                    );
                }
                this.dialogApply = true;
            }
        },
        nameGet(e) {
            let data = {
                param: {
                    companyName: e,
                },
            };
            return nameGet(data);
        },
        getCustomerId() {
            let data = {
                param: {
                    id: this.tableRow.customerId,
                },
            };
            return getCustomerId(data);
        },
        // 申请报备
        applyReport() {
            let data = {
                param: {
                    id: this.tableRow.id,
                    reportStatus: 8,
                },
            };
            reportUpdate(data).then((res) => {
                if (res.code == 200) {
                    let applyNum = Number(sessionStorage.getItem('applyNum'));
                    sessionStorage.setItem('applyNum', applyNum + 1);
                    this.$message.success('操作成功');
                    this.handleDialog();
                }
            });
        },

        // 报备信息
        onReportDetail(row) {
            this.$router.push({
                path: '/aliReportingdetails',
                query: {
                    id: row.id,
                },
            });
        },
        onDetails(row) {
            let data = {
                param: {
                    id: row.customerId,
                },
            };
            getCustomerId(data).then((res) => {
                let btn = JSON.parse(sessionStorage.getItem('isButArr'));
                let type = false;
                sessionStorage.setItem(
                    'showNum',
                    JSON.stringify({
                        saveNum: this.saveNum,
                        applyNum: this.applyNum,
                    })
                );
                for (let i in btn) {
                    if (btn[i].route == 'cusDetails') {
                        type = true;
                        break;
                    }
                }
                if (type) {
                    this.$router.push({
                        path: '/customerdetails',
                        query: {
                            type: type,
                            isTab: 1,
                        },
                    });
                } else {
                    this.$router.push({
                        path: '/customerdetails',
                        query: {
                            isTab: 1,
                        },
                    });
                }
                this.$formDetails.commit(
                    'detailsData',
                    JSON.stringify(res.data)
                );
                this.$choiceLabel.commit('getType', '2');
            });
        },

        // 业务操作历史
        onHistory(row) {
            this.tableRow = row;
            this.drawerHistory = true;
            this.reportGet();
        },
        reportHistory(row) {
            let data = {
                param: {
                    nicheId: this.tableRow.id,
                },
                pageNum: 0,
                pageSize: 0,
            };
            reportHistory(data).then((res) => {
                if (res.code == 200) {
                    this.$refs.history.getData(row, res.data.list);
                }
            });
        },
        // 重置
        searchEmpty() {
            this.searchVal = {
                field: 1,
                followTime: '',
                followDate: [],
                libraryCategoryVal: '',
            };
            this.name = '';
            this.active = '已报备';
            this.getData();
        },
        handleClose() {
            this.dialog = false;
            this.drawerReport = false;
            this.drawerTrial = false;
            this.drawerAddDomain = false;
            this.drawerReportDetail = false;
            this.drawerHistory = false;
            this.drawerAgainReport = false;
            this.drawerUpdateReport = false;
            sessionStorage.removeItem('customerlist');
            this.getData();
        },
        handleDialog() {
            this.dialogrevoke = false;
            this.dialogDelay = false;
            this.dialogChangeTrial = false;
            this.dialogApply = false;
            this.dialogGiveUp = false;
            sessionStorage.removeItem('customerlist');
            this.getData();
        },
    },
    beforeDestroy() {
        sessionStorage.removeItem('saveNum');
        sessionStorage.removeItem('applyNum');
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.customer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
        }
        .search-two {
            padding-bottom: 10px;
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #fff;
        padding: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        .title_left_btn {
            position: relative;
            .toast_gif {
                position: absolute;
                width: 16px;
                left: 132px;
                top: -5px;
            }
            .toast_gif2 {
                position: absolute;
                width: 16px;
                left: 385px;
                top: -5px;
            }
            span {
                display: inline-block;
                padding: 4px 13px;
                border-radius: 20px;
                font-size: 13px;
                color: #666666;
                border: 1px solid #f0f0f0;
                cursor: pointer;
            }
            span + span {
                margin-left: 12px;
            }
            .active {
                background: #e9f2ff;
                color: #2370eb;
            }
        }
        .add {
            border-color: #d9d9d9;
            color: #fff;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }

        button {
            font-size: 12px;
            padding: 0 20px 0 12px;
            height: 26px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
            background: #2370eb;
            i {
                margin-right: 6px;
                font-size: 12px;
            }
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Times {
        display: inline-block;
        width: 20px;
        line-height: 16px;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        background: #ff760f;
        border-radius: 63px 63px 63px 1px;
        text-align: center;
    }
    .Timess {
        background: #ff9385;
        margin-left: 3px;
    }
}
.content {
    height: 200px;
    padding-left: 50px;
    p {
        margin-top: 50px;
        font-size: 14px;
        color: #333;
        line-height: 30px;
    }
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
.dl_con {
    width: 80%;
    margin: 35px auto 100px;
    text-align: center;
    color: #333;
    font-weight: 600;
    font-size: 14px;
    i {
        font-weight: 400;
        font-size: 60px;
        color: #f5a623;
        display: block;
        margin-bottom: 20px;
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
    .el-button--primary {
        background-color: #2370eb;
    }
}
/deep/ .el-badge__content.is-fixed {
    top: 6px !important;
    right: 13px !important;
}
.info {
    font-size: 12px;
    span {
        color: #666666;
    }
    em {
        font-weight: normal;
        font-style: normal;
        margin-right: 5px;
    }
    span:last-child {
        margin-left: 10px;
    }
    i {
        margin-right: 5px;
    }
}
.slee {
    /deep/ .el-input__inner {
        font-weight: 600;
        color: #333;
    }
}
</style>
