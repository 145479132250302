<template>
    <div class="trial_box">
        <BaseInfo ref="baseInfo"></BaseInfo>
        <el-form
            ref="form"
            label-position="left"
            size="small"
            label-width="80px"
        >
            <el-form-item label="域名" required style="margin-left: -10px">
                <el-select
                    v-model="form.companyDomain"
                    clearable
                    placeholder="请选择"
                    style="width: 240px"
                >
                    <el-option
                        v-for="item in domainList"
                        :key="item.domain"
                        :label="item.domain"
                        :value="item.domain"
                    >
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="用户数" required style="margin-left: -10px">
                <el-input
                    style="width: 240px"
                    autocomplete="off"
                    v-model="form.trialNumber"
                    placeholder="请输入用户数"
                    clearable
                    @input="
                        form.trialNumber = form.trialNumber.replace(
                            /[^0-9.]/g,
                            ''
                        )
                    "
                ></el-input>
            </el-form-item>
            <el-form-item label="试用天数" required style="margin-left: -10px">
                <el-input
                    style="width: 240px"
                    v-model="form.trialDays"
                    placeholder="请输入试用天数"
                    clearable
                    @input="
                        form.trialDays = form.trialDays.replace(/[^0-9.]/g, '')
                    "
                ></el-input>
            </el-form-item>
            <el-form-item label="备注" style="margin-left: -10px">
                <el-input
                    type="textarea"
                    v-model="form.applyRemark"
                    placeholder="请输入备注"
                    style="width: 240px"
                    rows="6"
                ></el-input>
            </el-form-item>
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: 270px;
                "
                @click="onClose"
                >取消</el-button
            >

            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff"
                size="mini"
                @click="onSubmit"
                >申请试用</el-button
            >
        </div>
    </div>
</template>
<script>
import { reportUpdate, reportDomainList } from '@/api/report/report.js';
import BaseInfo from '../components/baseInfo.vue';
export default {
    data() {
        return {
            form: {},
            domainList: [],
            tableRow: {},
        };
    },
    components: {
        BaseInfo,
    },
    methods: {
        getData(row) {
            this.form = {};
            sessionStorage.setItem('customerlist', 1);
            this.tableRow = row;
            setTimeout(() => {
                this.$refs.baseInfo.getData(row);
            }, 0);
            this.reportDomainList(row.id);
        },
        reportDomainList(nicheId) {
            let data = {
                param: {
                    nicheId,
                    auditStatus: 1,
                },
            };
            reportDomainList(data).then((res) => {
                if (res.code == 200) {
                    this.domainList = res.data.list;
                }
            });
        },

        onClose() {
            this.form = {};
            this.$emit('close');
        },
        onSubmit() {
            let data = {
                param: {
                    id: this.tableRow.id,
                    trialStatus: 7,
                },
            };
            if (!this.form.companyDomain) {
                return this.$message.error('请选择域名');
            }
            if (!this.form.trialNumber) {
                return this.$message.error('请输入用户数');
            }
            if (!this.form.trialDays) {
                return this.$message.error('请输入试用天数');
            }
            data.param.trialNumber = this.form.trialNumber;
            data.param.trialDays = this.form.trialDays;
            if (this.form.applyRemark) {
                data.param.applyRemark = this.form.applyRemark;
            }
            data.param.trialDomain = this.form.companyDomain;
            reportUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.trial_box {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .el-form {
        width: 80%;
        margin: 0 auto;
    }
    .brand {
        display: inline-block;
        width: 260px;
        padding-left: 10px;
        background: #fafafa;
        margin-left: 10px;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
    .bottom-but {
        width: 480px;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        position: fixed;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
}
/deep/ .el-textarea__inner {
    resize: none;
}
</style>
