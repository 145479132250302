<template>
    <div class="add_report_box">
        <el-form
            ref="form"
            style="margin-top: 20px; margin-left: 60px; padding-bottom: 50px"
            label-position="left"
            :model="form"
            size="small"
            label-width="100px"
        >
            <el-form-item label="报备品牌" style="margin-left: -10px">
                <span class="brand">阿里企业邮箱</span>
                <div class="info">
                    <i
                        class="el-icon-warning-outline"
                        style="color: #f5a623"
                    ></i>
                    <span>最大报备名额：</span
                    ><em style="color: #d0021b">{{
                        row.maxReportNumber || 0
                    }}</em>
                    <span>已报备：</span
                    ><em style="color: #2370eb">{{
                        row.consumedNumber + row.channelReportNumber || 0
                    }}</em>
                </div>
                <!-- <div class="info">
                    <span>最大报备名额：</span>{{ row.maxReportNumber || 0 }}
                    <span>已报备：</span
                    >{{ row.consumedNumber + row.channelReportNumber || 0 }}
                </div> -->
            </el-form-item>

            <div v-if="!isChannel">
                <el-form-item label="客户" required style="margin-left: -10px">
                    <el-select
                        allow-create
                        filterable
                        :remote-method="remoteMethod"
                        remote
                        v-model="value"
                        size="small"
                        style="width: 240px"
                        placeholder="请选择搜索或直接输入"
                        value-key="id"
                        :filter-method="dataFilter"
                        @change="getCompanyData"
                        ref="searchSelect"
                        @clear="setValueNull"
                    >
                        <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.companyName"
                            :value="item"
                        >
                            <!-- <span style="float: left">{{ item.companyName }}</span>
                        <span
                            style="
                                float: right;
                                color: #8492a6;
                                font-size: 13px;
                            "
                            >{{ item.type == 1 ? '线索' : '客户' }}</span
                        > -->
                        </el-option>
                    </el-select>

                    <p
                        style="color: #666666; font-size: 12px"
                        v-if="
                            (enterpriseType == 1 &&
                                aiChaStatus != 1 &&
                                form.areaType == 1) ||
                            (aiChaStatus != 1 &&
                                form.areaType == 1 &&
                                form.enterpriseType == 1 &&
                                radio != 1 &&
                                value != '')
                        "
                    >
                        <i class="el-icon-warning" style="color: #f5a623"></i>
                        未查到工商局注册信息，请先修改客户名称 <br />
                        (海外客户请先调整所在区域为海外)
                    </p>
                    <p style="color: #666666; font-size: 12px" v-else>
                        <i class="el-icon-warning" style="color: #f5a623"></i>
                        若客户名称包含括号，请使用中文输入法下的括号
                    </p>
                    <p
                        class="domain_rules_box2"
                        v-if="typeof value === 'string' && value !== ''"
                    >
                        <el-button
                            v-if="tag1"
                            type="primary"
                            class="el-icon-success"
                            circle
                            size="mini"
                        ></el-button>

                        <el-button
                            v-else
                            class="el-icon-warning"
                            type="primary"
                            circle
                            size="mini"
                        ></el-button>
                        <span v-if="!tag1">重复</span>
                    </p>
                </el-form-item>

                <el-form-item
                    v-if="radio != 1 && value != '' && companyList.length == 0"
                    style="margin: 0; margin-top: -20px"
                >
                    <span style="color: #f56c6c; font-size: 12px"
                        >新客户需要完善客户信息</span
                    >
                </el-form-item>
                <div
                    class="companyList"
                    v-if="radio != 1 && value != '' && companyList.length > 0"
                >
                    <el-table
                        :data="companyList"
                        style="width: 100%"
                        :header-cell-style="{
                            background: '#FAFAFA',
                            color: '#333333',
                            'font-size': '14px',
                            'font-weight': '500',
                        }"
                    >
                        <el-table-column width="30">
                            <template slot-scope="scope">
                                <el-radio
                                    v-model="tableRadio"
                                    :label="scope.row"
                                    ><i></i
                                ></el-radio>
                            </template>
                        </el-table-column>
                        <el-table-column prop="companyName" label="客户名称">
                        </el-table-column>
                    </el-table>
                    <!-- <el-button size="mini" @click="onSync()">关联客户</el-button> -->
                </div>
                <el-form-item
                    label="企业类型"
                    required
                    v-if="radio != 1 && value != ''"
                    style="margin-left: -10px"
                >
                    <el-radio-group
                        :disabled="companyList.length > 0"
                        v-model="form.enterpriseType"
                        @change="changeEnterpriseType"
                    >
                        <el-radio :label="1">企业</el-radio>
                        <el-radio :label="2">个人</el-radio>
                        <el-radio :label="3">外资</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item
                    label="联系人"
                    required
                    style="margin-left: -10px"
                >
                    <el-select
                        allow-create
                        filterable
                        remote
                        v-model="value1"
                        :remote-method="remoteMethod1"
                        size="small"
                        style="width: 240px"
                        placeholder="请选择搜索或直接输入"
                        value-key="id"
                        :filter-method="dataFilter1"
                        @change="getCompanyData1()"
                        ref="searchSelect1"
                    >
                        <el-option
                            v-for="item in value.customerContactList"
                            :key="item.id"
                            :label="item.contactName"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="联系电话"
                    required
                    style="margin-left: -10px"
                >
                    <el-select
                        allow-create
                        filterable
                        remote
                        v-model="value2"
                        size="small"
                        style="width: 240px"
                        :remote-method="remoteMethod2"
                        placeholder="请选择搜索或直接输入"
                        value-key="contactContent"
                        :filter-method="dataFilter2"
                        ref="searchSelec2"
                        @change="getCompanyData2()"
                    >
                        <el-option
                            v-for="item in value1.customerContactWayDOList"
                            :key="item.contactContent"
                            :label="item.contactContent"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div v-if="isChannel">
                <el-form-item
                    label="报备渠道"
                    required
                    style="margin-left: -10px"
                >
                    <el-select
                        v-model="form.channelManageId"
                        size="small"
                        style="width: 240px"
                        filterable
                        placeholder="请选择或搜索报备渠道"
                    >
                        <el-option
                            :label="item.channelName"
                            v-for="item in channelListData"
                            :key="item.id"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="联系人"
                    required
                    style="margin-left: -10px"
                >
                    <el-input
                        v-model="form.contactName"
                        placeholder="请输入联系人"
                        style="width: 240px"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="联系电话"
                    required
                    style="margin-left: -10px"
                >
                    <el-input
                        v-model="form.contactPhone"
                        placeholder="请输入联系电话"
                        style="width: 240px"
                    ></el-input>
                </el-form-item>
            </div>
            <el-form-item label="报备名称" required style="margin-left: -10px">
                <el-input
                    v-model="form.reportName"
                    placeholder="请输入报备企业名称"
                    style="width: 240px"
                    @input="reportNameInput"
                ></el-input>
                <p
                    style="color: #666666; font-size: 12px"
                    v-if="!tag && form.reportName"
                >
                    <i
                        class="el-icon-warning-outline"
                        style="color: #f5a623"
                    ></i>
                    本报备名称重复，请修改
                </p>
                <div class="domain_rules_box" v-if="form.reportName">
                    <el-button
                        v-if="tag"
                        type="primary"
                        class="el-icon-success"
                        circle
                        size="mini"
                    ></el-button>
                    <el-button
                        v-else
                        class="el-icon-warning"
                        type="primary"
                        circle
                        size="mini"
                    ></el-button>
                </div>
            </el-form-item>
            <el-form-item label="所在区域" required style="margin-left: -10px">
                <el-radio-group
                    v-model="form.areaType"
                    @change="changeEnterpriseType2"
                >
                    <el-radio :label="1">国内</el-radio>
                    <el-radio :label="2">海外</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="所在地" required style="margin-left: -10px">
                <el-cascader
                    v-if="form.areaType == 1"
                    placeholder="请选择所在地"
                    style="width: 240px"
                    clearable
                    v-model="address"
                    :options="$address.cascader()"
                ></el-cascader>
                <el-input
                    v-if="form.areaType == 2"
                    v-model="form.abroadArea"
                    placeholder="请输入所在地"
                    style="width: 240px"
                ></el-input>
            </el-form-item>
            <el-form-item label="报备理由" required style="margin-left: -10px">
                <el-input
                    type="textarea"
                    v-model="form.reportReason"
                    placeholder="请输入报备理由"
                    style="width: 240px"
                    rows="6"
                ></el-input>
            </el-form-item>
            <el-form-item
                :label="'域名' + (index + 1)"
                required
                style="margin-left: -10px; position: relative"
                v-for="(item, index) in nicheAliDomainDOList"
                :key="index"
                class="domain_box"
            >
                <el-input
                    v-if="typeof value == 'string'"
                    style="width: 240px"
                    v-model="item.domain"
                    placeholder="请输入域名(去掉www开头)"
                    @input="domainInput2(item.domain, index)"
                ></el-input>
                <el-select
                    v-else
                    allow-create
                    filterable
                    :remote-method="
                        (val) => {
                            remoteMethod3(val, index);
                        }
                    "
                    remote
                    v-model="item.domain"
                    size="small"
                    style="width: 240px"
                    placeholder="请选择搜索或直接输入域名(去掉www开头)"
                    value-key="index"
                    :filter-method="
                        (val) => {
                            dataFilter3(val, index);
                        }
                    "
                    @change="
                        (val) => {
                            getCompanyData3(val, index);
                        }
                    "
                    ref="searchSelect"
                    @clear="setValueNull"
                >
                    <el-option
                        v-for="(item, index) in domainList"
                        :key="index"
                        :label="item.domain"
                        :value="item.domain"
                    >
                    </el-option>
                </el-select>

                <p
                    style="color: #666666; font-size: 12px"
                    v-if="item.tag === false && item.domain"
                >
                    <i
                        class="el-icon-warning-outline"
                        style="color: #f5a623"
                    ></i>
                    本域名重复无法报备，请修改或删除
                </p>
                <div
                    class="domain_rules_box"
                    v-if="
                        item.domain && (item.tag === false || item.tag === true)
                    "
                >
                    <el-button
                        v-if="item.tag"
                        type="primary"
                        class="el-icon-success"
                        circle
                        size="mini"
                    ></el-button>
                    <el-button
                        v-else
                        class="el-icon-warning"
                        type="primary"
                        circle
                        size="mini"
                    ></el-button>
                </div>
                <div class="domain_update_box">
                    <el-button
                        v-if="index == 0"
                        @click="addDomain"
                        type="primary"
                        class="el-icon-plus"
                        style="background: #fff"
                        circle
                        size="mini"
                    ></el-button>
                    <el-button
                        v-if="index > 0"
                        @click="delDomain(index)"
                        class="el-icon-minus"
                        type="primary"
                        circle
                        size="mini"
                        style="background: #fff"
                    ></el-button>
                </div>
            </el-form-item>
            <el-form-item
                label="预计用户数"
                required
                style="margin-left: -10px"
            >
                <el-select
                    v-model="form.estimateNumber"
                    style="width: 240px"
                    placeholder="请选择预计用户数"
                >
                    <el-option
                        v-for="item in $MailStatus.estimateNumber()"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="预计年限" required style="margin-left: -10px">
                <el-select
                    v-model="form.estimateTerm"
                    style="width: 240px"
                    placeholder="请选择预计年限"
                >
                    <el-option
                        v-for="item in $MailStatus.estimateTerm()"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                label="合作人"
                style="position: relative; margin-left: -10px"
                class="domain_box"
                v-for="(item, index) in nicheCollaboratorDOList"
            >
                <el-select
                    v-model="item.collaboratorAdminId"
                    size="small"
                    style="width: 240px"
                    filterable
                    clearable
                    placeholder="请选择成员"
                    @change="
                        (val) => {
                            chnageCollaborator(val, index);
                        }
                    "
                >
                    <el-option
                        v-for="item in userName"
                        :label="item.nickName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <div class="domain_update_box">
                    <el-button
                        v-if="index == 0"
                        @click="addChanceCollaborator"
                        type="primary"
                        class="el-icon-plus"
                        style="background: #fff"
                        circle
                        size="mini"
                    ></el-button>
                    <el-button
                        v-if="index > 0"
                        @click="delChanceCollaborator(index)"
                        class="el-icon-minus"
                        type="primary"
                        circle
                        size="mini"
                        style="background: #fff"
                    ></el-button>
                </div>
            </el-form-item>
            <el-form-item label="预计合作时间" style="margin-left: -10px">
                <el-date-picker
                    v-model="form.estimateCooperateTime"
                    size="small"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 240px"
                    type="date"
                    placeholder="请选择预计合作时间"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="预计金额" style="margin-left: -10px">
                <el-input
                    v-model="form.estimateAmount"
                    placeholder="请输入预计金额"
                    style="width: 240px"
                    @input="
                        () => {
                            form.estimateAmount = form.estimateAmount.replace(
                                /[^0-9]/g,
                                ''
                            );
                        }
                    "
                ></el-input>
            </el-form-item>
            <el-form-item label="备注" style="margin-left: -10px">
                <el-input
                    type="textarea"
                    v-model="form.reportRemark"
                    size="small"
                    placeholder="请输入备注"
                    style="width: 240px"
                    rows="6"
                ></el-input>
            </el-form-item>
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: none; border-color: #d5d6df; color: #666666"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                size="mini"
                style="background: #2370eb; border-color: #d5d6df; color: #fff"
                @click="onSubmitSave(6)"
                >保存</el-button
            >
            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff"
                size="mini"
                v-if="
                    Number(row.maxReportNumber) - Number(row.consumedNumber) >=
                    1
                "
                @click="onSubmitSave(8)"
                >申请报备</el-button
            >
        </div>
    </div>
</template>

<script>
import {
    reportAdd,
    reportList,
    customerList,
    checkDomain,
} from '@/api/report/report.js';
import {
    aliNotReport,
    contactAdd,
    addCustomer,
    getCustomerAdmin,
    getCustomerId,
    customerAddMy,
} from '@/api/user/ae/customer.js';
import { nameGet, roleList } from '@/api/customer/customer.js';
import { channeManagelList as channelManagelList } from '@/api/reportchannel/reportchannel.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            form: {
                reportName: '',
                areaType: 1,
                enterpriseType: 1,
            },
            nicheAliDomainDOList: [{}],
            options: [],
            address: [],
            optionsFilter: [],
            radio: '1',
            radio1: '1',
            radio2: '1',
            value: '',
            value1: '',
            value2: '',
            tag: '',
            tag1: '',
            row: {},
            domainList: [],
            enterpriseType: '',
            aiChaStatus: 1,
            str: '',
            tableRadio: {},
            companyList: [],
            isChannel: '',
            channelListData: [],
            nicheCollaboratorDOList: [{}],
            userName: [],
        };
    },
    components: {},
    created() {},
    mounted() {},
    methods: {
        // 获取部门成员
        getUserList() {
            let data = {
                param: {},
            };
            roleList(data).then((res) => {
                this.userName = res.data;
            });
        },
        getData(name, row = '', isChannel) {
            this.domainList = [];
            this.isChannel = isChannel;
            sessionStorage.setItem('customerlist', 1);
            this.row = JSON.parse(JSON.stringify(row));
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                },
                pageNum: 1,
                pageSize: 10,
            };
            if (name) {
                data.param.name = name;
            }
            customerList(data).then((res) => {
                this.options = res.data.list;
                this.optionsFilter = res.data.list;
                this.getUserList();
            });
        },
        channelManagelList() {
            let data = {
                param: {
                    status: 1,
                },
                pageNum: 0,
                pageSize: 0,
            };
            channelManagelList(data).then((res) => {
                if (res.code == 200) {
                    this.channelListData = res.data.list;
                }
            });
        },
        addDomain() {
            this.nicheAliDomainDOList.push({});
        },
        delDomain(index) {
            this.nicheAliDomainDOList.splice(index, 1);
        },
        remoteMethod(query) {
            let data = {
                param: {
                    companyName: query,
                    enterpriseType: 1,
                },
            };
            getCustomerAdmin(data).then((res) => {
                if (res.code == 200) {
                    if (res.data.list && res.data.list.length > 0) {
                        this.companyList = res.data.list;
                        this.tag1 = false;
                    } else {
                        this.companyList = [];
                        this.tag1 = true;
                    }
                }
            });
            this.nameGet(query);
            this.getData(query, this.row, this.isChannel);
            this.value = query;
            this.value1 = '';
            this.value2 = '';
            this.form.reportName = query;
            this.reportNameInput(query);
            this.radio = '2';
        },
        dataFilter(val) {
            this.value = val;
            if (val) {
                this.options = this.optionsFilter.filter((item) => {
                    if (
                        item.companyName.includes(val) ||
                        item.companyName
                            .toUpperCase()
                            .includes(val.toUpperCase())
                    ) {
                        return true;
                    }
                });
            } else {
                this.options = this.optionsFilter;
            }
            this.getCompanyData();
        },
        setValueNull() {
            this.getData('', this.row, this.isChannel);
        },
        getCompanyData(e) {
            this.tag1 = '';
            this.domainList = [];

            this.form.reportName = e.companyName || e;
            if (e.companyName) {
                this.reportNameInput(e.companyName);
                this.nameGet(e.companyName);
                this.getCustomerId();
            } else {
                this.reportNameInput(e);
                this.nameGet(e);
            }

            this.radio = '1';
        },
        changeEnterpriseType(val) {
            if (val == 1 && this.form.areaType == 1 && !this.isChannel) {
                if (typeof this.value == 'object') {
                    this.nameGet(this.value.companyName);
                } else {
                    this.nameGet(this.value);
                }
            }
        },
        changeEnterpriseType2(val) {
            if (val == 1 && this.form.enterpriseType == 1 && !this.isChannel) {
                if (typeof this.value == 'object') {
                    this.nameGet(this.value.companyName);
                } else {
                    this.nameGet(this.value);
                }
            }
        },
        nameGet(e) {
            let data = {
                param: {
                    companyName: e,
                },
            };
            nameGet(data).then((res) => {
                if (res.code == 200) {
                    this.aiChaStatus = res.data.aiChaStatus;
                }
            });
        },
        dataFilter1(val) {
            this.value1 = val;
        },
        getCompanyData1() {
            this.radio1 = '1';
        },
        getCustomerId() {
            let data = {
                param: {
                    id: this.value.id,
                },
            };
            getCustomerId(data).then((res) => {
                this.enterpriseType = res.data.enterpriseType;
                this.form.enterpriseType = res.data.enterpriseType;
                if (res.data.companyDomain) {
                    this.domainList.push({ domain: res.data.companyDomain });
                }
                if (res.data.customerDomainList.length > 0) {
                    for (let i = 0; i < res.data.customerDomainList.length; i++)
                        this.domainList.push({
                            domain: res.data.customerDomainList[i].domain,
                        });
                }
                this.nicheAliDomainDOList[0].domain =
                    this.domainList && this.domainList.length > 0
                        ? this.domainList[0].domain
                        : '';
                this.nicheAliDomainDOList[0].domain =
                    this.nicheAliDomainDOList[0].domain != ''
                        ? this.nicheAliDomainDOList[0].domain.replace(
                              'www.',
                              ''
                          )
                        : '';
                this.nicheAliDomainDOList[0].domain != ''
                    ? this.domainInput(this.nicheAliDomainDOList[0].domain, 0)
                    : '';
            });
        },
        remoteMethod1(query) {
            this.value1 = query;
            this.radio1 = '2';
            this.value2 = '';
        },
        dataFilter2(val) {
            this.value2 = val;
        },
        getCompanyData2() {
            this.radio2 = '1';
        },
        remoteMethod2(query) {
            this.value2 = query;
            this.radio2 = '2';
        },
        dataFilter3(val, index) {
            val = val.replace('www.', '');
            this.nicheAliDomainDOList[index].domain = val;
        },
        getCompanyData3(val, index) {
            val = val.replace('www.', '');
            console.log(val);
            this.domainInput(val, index);
        },
        remoteMethod3(query, index) {
            if (/^www/.test(query)) {
                this.nicheAliDomainDOList[index].domain = '';
                return;
            }

            this.domainInput(query, index);
        },
        // 报备名称校验
        reportNameInput(val) {
            let data = {
                param: {
                    reportName: val,
                    reportStatusList: [3, 8, 9],
                    productType: 2,
                },
                pageSize: 0,
                pageNum: 0,
            };
            reportList(data).then((res) => {
                if (res.code == 200) {
                    if (res.data.total > 0) {
                        this.tag = false;
                        res.data.list.forEach((item) => {
                            if (
                                item.adminName !=
                                sessionStorage.getItem('nickName')
                            ) {
                                this.tag = false;
                            } else {
                                this.tag = true;
                            }
                        });
                    } else {
                        this.tag = true;
                    }
                }
            });
        },
        domainInput2(val, index) {
            if (/^www/.test(val)) {
                this.nicheAliDomainDOList[index].domain = '';
                return;
            }

            this.domainInput(val, index);
        },
        // 域名重复校验
        domainInput(val, index) {
            this.nicheAliDomainDOList[index].domain = val;
            this.nicheAliDomainDOList = JSON.parse(
                JSON.stringify(this.nicheAliDomainDOList)
            );
            if (/^www/.test(val)) {
                this.nicheAliDomainDOList[index].tag = '';
                this.nicheAliDomainDOList = JSON.parse(
                    JSON.stringify(this.nicheAliDomainDOList)
                );
                return this.$message.error('域名不能包含www');
            }

            if (
                this.nicheAliDomainDOList.some((item, idx) => {
                    if (idx != index) {
                        if (item.domain && item.domain == val) {
                            return true;
                        }
                    }
                })
            ) {
                this.nicheAliDomainDOList[index].tag = false;
                return;
            }
            let data = {
                param: {
                    productType: 2,
                    domainList: [val],
                },
            };
            checkDomain(data).then((res) => {
                if (res.code != 200) {
                    this.nicheAliDomainDOList[index].tag = false;
                } else {
                    this.nicheAliDomainDOList[index].tag = true;
                }
                this.nicheAliDomainDOList = JSON.parse(
                    JSON.stringify(this.nicheAliDomainDOList)
                );
            });
        },
        // 添加联系人
        contactAdd(reportStatus) {
            if (this.form.areaType == 1) {
                this.form.provinceId = Number(this.address[0]) || 0;
                this.form.cityId = Number(this.address[1]) || 0;
                if (
                    (this.enterpriseType == 1 &&
                        this.aiChaStatus != 1 &&
                        this.form.areaType == 1) ||
                    (this.aiChaStatus != 1 &&
                        this.form.areaType == 1 &&
                        this.form.enterpriseType == 1)
                ) {
                    if (reportStatus == 8) {
                        return this.$message.error(
                            '客户名称有误，无法对客户进行报备'
                        );
                    }
                }
            }

            if (!this.value) {
                return this.$message.error('请输入或选择客户');
            }
            if (this.tag1 === false && !this.tableRadio.id) {
                return this.$message.error('客户名称重复，请修改');
            }
            if (!this.value1) {
                return this.$message.error('请输入或选择联系人');
            }
            if (!this.value2) {
                return this.$message.error('请输入或选择联系电话');
            }
            if (!this.form.reportName) {
                return this.$message.error('请输入报备名称');
            }
            if (!this.tag) {
                return this.$message.error('当前报备名称已报备，申请失败');
            }
            if (this.form.areaType == 1) {
                if (!this.form.provinceId) {
                    return this.$message.error('请选择所在地');
                }
            }
            if (this.form.areaType == 2) {
                if (!this.form.abroadArea) {
                    return this.$message.error('请输入所在地');
                }
            }
            if (!this.form.reportReason) {
                return this.$message.error('请输入报备理由');
            }
            for (let i in this.nicheAliDomainDOList) {
                if (!this.nicheAliDomainDOList[i].domain) {
                    return this.$message.error('请输入域名');
                }
                if (this.nicheAliDomainDOList[i].tag === false) {
                    return this.$message.error('域名不能重复');
                }
                if (/^www/.test(this.nicheAliDomainDOList[i].domain)) {
                    return this.$message.error('域名不能包含www');
                }
                // if () {
                //     return this.$message.error('域名不能重复');
                // }
            }
            if (!this.form.estimateNumber) {
                return this.$message.error('请选择预计用户数');
            }
            if (!this.form.estimateTerm) {
                return this.$message.error('请选择预计年限');
            }
            if (this.nicheAliDomainDOList.length > 10) {
                return this.$message.error('每条商机可报备域名不能超过10个');
            }
            this.form.reportName = this.Turn(this.form.reportName);
            let data = {
                param: {
                    customerContactDO: {},
                },
            };
            if (this.tableRadio.id) {
                data.param.customerContactDO = {
                    companyName: this.tableRadio.companyName,
                    contactName: this.value1,
                    customerContactWayDOList: [
                        {
                            contactContent: this.value2,
                            contactWay: 1,
                        },
                    ],
                };
                data.param.customerContactDO.customerId = this.tableRadio.id;
                console.log(this.tableRadio);
            } else {
                data.param.customerContactDO = {
                    companyName: this.value.companyName,
                    contactName: this.value1.contactName || this.value1,
                    customerContactWayDOList: [
                        {
                            contactContent:
                                this.value2.contactContent || this.value2,
                            contactWay: 1,
                        },
                    ],
                };
                data.param.customerContactDO.companyName = this.Turn(
                    data.param.customerContactDO.companyName
                );
                data.param.customerContactDO.customerId = this.value.id;
            }
            data.param.customerContactDO.followAdminId =
                sessionStorage.getItem('adminId');

            contactAdd(data).then((res) => {
                if (res.code == 200) {
                    this.saveReport(reportStatus);
                }
            });
        },
        Turn(srt) {
            var reg = /[\(]/g,
                reg2 = /[\)]/g;
            return srt.replace(reg, '（').replace(reg2, '）');
        },
        // 保存客户
        addCustomer(reportStatus) {
            if (this.form.areaType == 1) {
                this.form.provinceId = Number(this.address[0]) || 0;
                this.form.cityId = Number(this.address[1]) || 0;
                if (
                    (this.enterpriseType == 1 &&
                        this.aiChaStatus != 1 &&
                        this.form.areaType == 1) ||
                    (this.aiChaStatus != 1 &&
                        this.form.areaType == 1 &&
                        this.form.enterpriseType == 1)
                ) {
                    if (reportStatus == 8) {
                        return this.$message.error(
                            '客户名称有误，无法对客户进行报备'
                        );
                    }
                }
            }

            if (!this.value) {
                return this.$message.error('请输入或选择客户');
            }
            if (this.tag1 === false && !this.tableRadio.id) {
                return this.$message.error('客户名称重复，请修改');
            }
            if (!this.value1) {
                return this.$message.error('请输入或选择联系人');
            }
            if (!this.value2) {
                return this.$message.error('请输入或选择联系电话');
            }
            if (!this.form.reportName) {
                return this.$message.error('请输入报备名称');
            }
            if (!this.tag) {
                return this.$message.error('报备名称重复，请修改');
            }
            if (this.form.areaType == 1) {
                if (!this.form.provinceId) {
                    return this.$message.error('请选择所在地');
                }
            }
            if (this.form.areaType == 2) {
                if (!this.form.abroadArea) {
                    return this.$message.error('请输入所在地');
                }
            }
            if (!this.form.reportReason) {
                return this.$message.error('请输入报备理由');
            }
            for (let i in this.nicheAliDomainDOList) {
                if (!this.nicheAliDomainDOList[i].domain) {
                    return this.$message.error('请输入域名');
                }
                if (this.nicheAliDomainDOList[i].tag === false) {
                    return this.$message.error('域名不能重复');
                }
                if (/^www/.test(this.nicheAliDomainDOList[i].domain)) {
                    return this.$message.error('域名不能包含www');
                }
            }
            if (!this.form.estimateNumber) {
                return this.$message.error('请选择预计用户数');
            }
            if (!this.form.estimateTerm) {
                return this.$message.error('请选择预计年限');
            }
            if (this.nicheAliDomainDOList.length > 10) {
                return this.$message.error('每条商机可报备域名不能超过10个');
            }
            this.value = this.Turn(this.value);
            this.form.reportName = this.Turn(this.form.reportName);
            let data = {
                param: {
                    customerDO: {
                        provinceId: this.form.provinceId,
                        cityId: this.form.cityId,
                        enterpriseType: this.form.enterpriseType,
                        companyName: this.value,
                    },
                    customerContactList: [
                        {
                            companyName: this.value,
                            contactName: this.value1,
                            customerContactWayDOList: [
                                {
                                    contactContent: this.value2,
                                    contactWay: 1,
                                },
                            ],
                        },
                    ],
                },
            };

            addCustomer(data).then((res) => {
                if (res.code == 200) {
                    this.value = res.data.id;
                    this.saveReport(reportStatus);
                }
            });
        },
        //    保存
        onSubmitSave(reportStatus) {
            if (this.isChannel) {
                this.saveReport(reportStatus);
            } else {
                // 老客户
                if (this.radio == '1') {
                    // 老联系人
                    if (this.radio1 == '1') {
                        // 老联系电话
                        if (this.radio2 == '1') {
                            this.saveReport(reportStatus);
                        } else {
                            this.contactAdd(reportStatus);
                        }
                    } else {
                        this.contactAdd(reportStatus);
                    }
                } else {
                    if (this.companyList.length > 0) {
                        if (this.form.areaType == 1) {
                            this.form.provinceId = Number(this.address[0]) || 0;
                            this.form.cityId = Number(this.address[1]) || 0;
                            if (
                                (this.enterpriseType == 1 &&
                                    this.aiChaStatus != 1 &&
                                    this.form.areaType == 1) ||
                                (this.aiChaStatus != 1 &&
                                    this.form.areaType == 1 &&
                                    this.form.enterpriseType == 1)
                            ) {
                                if (reportStatus == 8) {
                                    return this.$message.error(
                                        '客户名称有误，无法对客户进行报备'
                                    );
                                }
                            }
                        }

                        if (!this.value) {
                            return this.$message.error('请输入或选择客户');
                        }
                        if (this.tag1 === false && !this.tableRadio.id) {
                            return this.$message.error('客户名称重复，请修改');
                        }
                        if (!this.value1) {
                            return this.$message.error('请输入或选择联系人');
                        }
                        if (!this.value2) {
                            return this.$message.error('请输入或选择联系电话');
                        }
                        if (!this.form.reportName) {
                            return this.$message.error('请输入报备名称');
                        }
                        if (!this.tag) {
                            return this.$message.error('报备名称重复，请修改');
                        }
                        if (this.form.areaType == 1) {
                            if (!this.form.provinceId) {
                                return this.$message.error('请选择所在地');
                            }
                        }
                        if (this.form.areaType == 2) {
                            if (!this.form.abroadArea) {
                                return this.$message.error('请输入所在地');
                            }
                        }
                        if (!this.form.reportReason) {
                            return this.$message.error('请输入报备理由');
                        }
                        for (let i in this.nicheAliDomainDOList) {
                            if (!this.nicheAliDomainDOList[i].domain) {
                                return this.$message.error('请输入域名');
                            }
                            if (this.nicheAliDomainDOList[i].tag === false) {
                                return this.$message.error('域名不能重复');
                            }
                            if (
                                /^www/.test(this.nicheAliDomainDOList[i].domain)
                            ) {
                                return this.$message.error('域名不能包含www');
                            }
                        }
                        if (!this.form.estimateNumber) {
                            return this.$message.error('请选择预计用户数');
                        }
                        if (!this.form.estimateTerm) {
                            return this.$message.error('请选择预计年限');
                        }
                        if (this.nicheAliDomainDOList.length > 10) {
                            return this.$message.error(
                                '每条商机可报备域名不能超过10个'
                            );
                        }
                        if (!this.tableRadio.id) {
                            return this.$message.error('报备失败，请选择客户');
                        }
                        let data = {
                            param: {
                                id: this.tableRadio.id,
                            },
                        };
                        customerAddMy(data).then((res) => {
                            if (res.code == 200) {
                                this.contactAdd(reportStatus);
                            }
                        });
                    } else {
                        this.addCustomer(reportStatus);
                    }
                }
            }
        },

        // 保存报备
        saveReport(reportStatus) {
            if (this.form.areaType == 1) {
                this.form.provinceId = Number(this.address[0]) || 0;
                this.form.cityId = Number(this.address[1]) || 0;
                if (
                    (this.enterpriseType == 1 &&
                        this.aiChaStatus != 1 &&
                        this.form.areaType == 1) ||
                    (this.aiChaStatus != 1 &&
                        this.form.areaType == 1 &&
                        this.form.enterpriseType == 1)
                ) {
                    if (reportStatus == 8) {
                        return this.$message.error(
                            '客户名称有误，无法对客户进行报备'
                        );
                    }
                }
            }

            if (this.isChannel) {
                if (!this.form.channelManageId) {
                    return this.$message.error('请选择报备渠道');
                }
                if (!this.form.contactName) {
                    return this.$message.error('请输入联系人');
                }
                if (!this.form.contactPhone) {
                    return this.$message.error('请输入联系电话');
                }
            } else {
                if (!this.value) {
                    return this.$message.error('请输入或选择客户');
                }
                if (this.tag1 === false && !this.tableRadio.id) {
                    return this.$message.error('客户名称重复，请修改');
                }
                if (!this.value1) {
                    return this.$message.error('请输入或选择联系人');
                }
                if (!this.value2) {
                    return this.$message.error('请输入或选择联系电话');
                }
            }
            if (!this.form.reportName) {
                return this.$message.error('请输入报备名称');
            }
            if (!this.tag) {
                return this.$message.error('报备名称重复，请修改');
            }
            if (this.form.areaType == 1) {
                if (!this.form.provinceId) {
                    return this.$message.error('请选择所在地');
                }
            }
            if (this.form.areaType == 2) {
                if (!this.form.abroadArea) {
                    return this.$message.error('请输入所在地');
                }
            }
            if (!this.form.reportReason) {
                return this.$message.error('请输入报备理由');
            }
            for (let i in this.nicheAliDomainDOList) {
                if (!this.nicheAliDomainDOList[i].domain) {
                    return this.$message.error('请输入域名');
                }
                if (this.nicheAliDomainDOList[i].tag === false) {
                    return this.$message.error('域名不能重复');
                }
                if (/^www/.test(this.nicheAliDomainDOList[i].domain)) {
                    return this.$message.error('域名不能包含www');
                }
            }
            if (!this.form.estimateNumber) {
                return this.$message.error('请选择预计用户数');
            }
            if (!this.form.estimateTerm) {
                return this.$message.error('请选择预计年限');
            }
            if (this.nicheAliDomainDOList.length > 10) {
                return this.$message.error('每条商机可报备域名不能超过10个');
            }
            this.form.reportName = this.Turn(this.form.reportName);
            let data = {
                param: {
                    ...this.form,
                    reportStatus,
                    productType: 2,
                    nicheCollaboratorDOList:
                        this.nicheCollaboratorDOList.filter((item) => {
                            return item.collaboratorAdminId;
                        }),
                },
            };

            if (this.form.areaType == 1) {
                delete data.param.abroadArea;
            } else {
                delete data.param.provinceId;
                delete data.param.cityId;
            }
            if (this.isChannel) {
                data.param.channelManageId = this.form.channelManageId;
                data.param.contactName = this.form.contactName;
                data.param.nicheCategory = 2;
                data.param.contactPhone = this.form.contactPhone;
            } else {
                data.param.nicheCategory = 1;
                if (this.tableRadio.id) {
                    data.param.customerId = this.tableRadio.id;
                } else {
                    data.param.customerId = this.value.id || this.value;
                }
                data.param.contactName = this.value1.contactName || this.value1;
                data.param.contactPhone =
                    this.value2.contactContent || this.value2;
            }
            data.param.nicheDomainDOList = this.nicheAliDomainDOList.map(
                (item) => {
                    return {
                        domain: item.domain,
                    };
                }
            );
            delete data.param.enterpriseType;
            reportAdd(data).then((res) => {
                if (res.code == 200) {
                    let saveNum = Number(sessionStorage.getItem('saveNum'));
                    let applyNum = Number(sessionStorage.getItem('applyNum'));
                    if (reportStatus == 6) {
                        sessionStorage.setItem('saveNum', saveNum + 1);
                    } else {
                        sessionStorage.setItem('applyNum', applyNum + 1);
                    }
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        addChanceCollaborator() {
            if (this.nicheCollaboratorDOList.length == 5) {
                return this.$message.error('合作人最多添加5个');
            }
            this.nicheCollaboratorDOList.push({
                collaboratorAdminId: '',
            });
        },
        delChanceCollaborator(index) {
            this.nicheCollaboratorDOList.splice(index, 1);
        },
        chnageCollaborator(val, index) {
            if (
                this.nicheCollaboratorDOList.some((item, idx) => {
                    return item.collaboratorAdminId == val && index != idx;
                })
            ) {
                this.nicheCollaboratorDOList[index].collaboratorAdminId = '';
                return this.$message.error('合作人重复，请重新选择');
            }
        },
        onClose() {
            for (let i in this.form) {
                this.form[i] = '';
            }
            this.nicheAliDomainDOList = [
                {
                    domain: '',
                },
            ];
            this.value = '';
            this.value1 = '';
            this.value2 = '';
            this.address = [];
            this.$emit('close');
            sessionStorage.removeItem('customerlist');
        },
    },
};
</script>

<style lang="scss" scoped>
.companyList {
    border: 1px solid #e5e5e5;
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
    button {
        background: #2370eb;
        border-radius: 13px;
        color: #ffffff;
        margin: 12px;
        margin-left: 40px;
    }
}
.add_report_box {
    width: 100%;
    height: 100%;
    .brand {
        display: inline-block;
        width: 240px;
        box-sizing: border-box;
        padding-left: 10px;
        background: #fafafa;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }

    .domain_box {
        /deep/ .el-input__inner {
            padding-right: 20px !important;
        }
        .el-button--mini.is-circle {
            padding: 2px;
            background: #fff;
            color: #2370eb;
            font-weight: 600;
            width: 20px;
            height: 20px;
            border: 2px solid #2370eb;
        }

        .domain_update_box {
            position: absolute;
            right: 80px;
            top: 0px;
        }
    }
    .domain_rules_box {
        position: absolute;
        right: 110px;
        top: 2px;
        .el-button--mini.is-circle {
            padding: 2px;
            width: 20px;
            height: 20px;
            background-color: transparent;
            border: none;
            margin-left: 0;
            font-size: 16px;
            font-weight: 600;
        }
        .el-icon-success {
            color: #46a136 !important;
        }
        .el-icon-warning {
            color: #f5a623 !important;
        }
    }
    .domain_rules_box2 {
        position: absolute;
        right: 60px;
        top: 0px;
        span {
            font-size: 12px;
            color: #f5a623;
        }
        .el-button--mini.is-circle {
            padding: 2px;
            width: 20px;
            height: 20px;
            background-color: transparent;
            border: none;
            margin-left: 0;
            font-size: 13px;
            font-weight: 600;
        }
        .el-button--mini.is-circle.el-icon-success {
            color: #46a136 !important;
            margin-left: -40px;
            font-size: 16px;
            top: 2px;
        }
        .el-icon-warning {
            color: #f5a623 !important;
        }
    }
    .bottom-but {
        width: 480px;
        line-height: 48px;
        text-align: right;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        position: fixed;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
}
/deep/ .el-textarea__inner {
    resize: none;
}
.info {
    font-size: 12px;
    span {
        color: #666666;
    }
    em {
        font-weight: normal;
        font-style: normal;
        margin-right: 5px;
    }
    span:last-child {
        margin-left: 10px;
    }
}
</style>
